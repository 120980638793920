import { applyDensity, applyMode, Density, Mode } from '@amzn/awsui-global-styles';
import capitalize from 'lodash/capitalize';
import { useCallback } from 'react';

import { useLocalStorage } from '@/common/hooks/useLocalStorage';
import { DEFAULT_CONTENT_DENSITY, DEFAULT_VISUAL_MODE } from '@/utilities/constants';

const CACHE_KEY_CONTENT_DENSITY = 'density';
const CACHE_KEY_VISUAL_MODE = 'theme';

type IContentControls = {
  initialize: () => void;
  reset: () => void;
  toggle: () => void;
};

// checks in this order: if localStorage theme is available -> if there is OS preferences -> defaults to light
function getDefaultVisualMode(): Mode {
  if (window) {
    const mql = window.matchMedia('(prefers-color-scheme: dark)');
    return mql.matches ? Mode.Dark : Mode.Light;
  }
  return DEFAULT_VISUAL_MODE;
}

function applyContentDensity(value: Density): void {
  applyDensity(value, document.documentElement);
  applyDensity(value, document.body);
}

function applyVisualMode(value: Mode): void {
  applyMode(value, document.documentElement);
  applyMode(value, document.body);
}

type UseVisualModeControls = {
  mode: Mode;
  setMode: (mode: Mode | `${Mode}`) => void;
} & IContentControls;

export function useVisualModeControls(): UseVisualModeControls {
  const [storedMode, setStoredMode, removeStoredMode] = useLocalStorage<Mode>({
    key: CACHE_KEY_VISUAL_MODE,
    defaultValue: getDefaultVisualMode(),
  });

  const initialize = useCallback(() => applyVisualMode(storedMode), [storedMode]);

  const reset = useCallback(removeStoredMode, [removeStoredMode]);

  const setMode = useCallback(
    (value: Mode | `${Mode}`) => {
      const mode = Mode[capitalize(value)];
      if (storedMode === mode) return;
      applyVisualMode(mode);
      setStoredMode(mode);
    },
    [setStoredMode, storedMode]
  );

  const toggle = useCallback(() => setMode(storedMode === Mode.Light ? Mode.Dark : Mode.Light), [setMode, storedMode]);

  return { initialize, reset, setMode, toggle, mode: storedMode };
}

type UseContentDensityControls = {
  density: Density;
  setDensity: (density: Density | `${Density}`) => void;
} & IContentControls;

export function useContentDensityControls(): UseContentDensityControls {
  const [storedDensity, setStoredDensity, removeStoredDensity] = useLocalStorage<Density>({
    key: CACHE_KEY_CONTENT_DENSITY,
    defaultValue: DEFAULT_CONTENT_DENSITY,
  });

  const initialize = useCallback(() => applyContentDensity(storedDensity), [storedDensity]);

  const reset = useCallback(removeStoredDensity, [removeStoredDensity]);

  const setDensity = useCallback(
    (value: Density | `${Density}`) => {
      const density = Density[capitalize(value)];
      if (storedDensity === density) return;
      applyContentDensity(density);
      setStoredDensity(density);
    },
    [setStoredDensity, storedDensity]
  );

  const toggle = useCallback(
    () => setDensity(storedDensity === Density.Comfortable ? Density.Compact : Density.Comfortable),
    [setDensity, storedDensity]
  );

  return { initialize, reset, setDensity, toggle, density: storedDensity };
}
