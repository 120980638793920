import { ElevateHelpContent, HelpLink } from '@/common/app-layout-components';

export type HelpContentName = keyof typeof HELP_CONTENT;

const WHAT_IS_ELEVATE: HelpLink = {
  href: 'https://w.amazon.com/bin/view/AWS/EnterpriseSupport/ElevateHire',
  text: 'What is Elevate?',
};

export const HELP_CONTENT = {
  interviewersUnderShepherdship: {
    title: 'Interviewers under shepherdship',
    content: (
      <div>
        <p>
          A table containing the details of every Interviewer managed by a calibration shepherd. From here, Shepherds can
          access Interviewer profiles and check/amend their Calibration status.
        </p>
      </div>
    ),
    links: [WHAT_IS_ELEVATE],
  },
  interviewerCalibrationShepherdLookupTable: {
    title: 'Lookup Calibration Shepherd',
    content: (
      <div>
        <p>
          A table containing every Interviewer in this ElevateHire Organization. The table can be used to identify the
          Interviewers Calibration Shepherd.
        </p>
      </div>
    ),
    links: [WHAT_IS_ELEVATE],
  },
  findAnInterviewer: {
    title: 'Find an interviewer',
    content: (
      <div>
        <p>
          A table containing all of the Interviewers in this ElevateHire region. The table also shows their calibration
          status for Loop and Phone Screen events.
        </p>
        <p>&apos;Not calibrated&apos; means the Interviewer has not yet been added to a calibration cohort.</p>
        <p>
          &apos;In calibration&apos; means the Interviewer has been added to a calibration cohort and will see their
          progress.
        </p>
        <p>
          &apos;Independent&apos; means the Interviewer has met the calibration requirements and can now Interview
          independently, without a shadow.
        </p>
        <p>
          &apos;Calibrated&apos; means the Interviewer is fully calibrated and can be shadowed by other Interviewers whom are
          &apos;In calibration&apos;.
        </p>
      </div>
    ),
    links: [WHAT_IS_ELEVATE],
  },
  availablePhoneScreenSlotsTable: {
    title: 'Available phone screen slots',
    content: (
      <div>
        <p>
          A table of all availability slots added by calibrated Interviewers. All slots are displayed in the preferred time
          zone of the listed Interviewer. An availability can be a single slot or recurring. For recurring series, you can
          see the dates available when you click &apos;request availability&apos;.
        </p>
        <p>
          Recruitment coordinators can request slots which allows them to lock them whilst they are sent to a candidate to
          confirm. You can also &apos;release request&apos; if a candidate declines the offered time, this releases an
          availability slot back into the pool. You can also create phone screen from an availability window. This will open
          the &apos;create phone screen&apos; page and auto-populate the interviewer and time as listed in the availability
          window.
        </p>
        <p>
          State can be either &apos;available&apos;, &apos;requested&apos; or &apos;claimed&apos;. &apos;available&apos; can
          be offered to a candidate by a recruitment coordinator. Slots should be &apos;requested&apos; once they are offered
          to a candidate. This prevents the Interviewer from deleting the window or another recruitment coordinator offering
          this window to another candidate.
        </p>
        <p>
          Level shows the level of the Interviewer who added this slot. Recruitment coordinators should ensure they offer
          suitable Interviewer windows dependent on the candidate level.
        </p>
        <p>Days column shows the days that an availability series is valid for.</p>
      </div>
    ),
    links: [WHAT_IS_ELEVATE],
  },
  phoneScreenGeneralInformation: {
    title: 'General information',
    content: (
      <div>
        <p>
          Create a single phone screen event. specify the date, time and timezone as well as the phone screen configuration
          for this event.
        </p>
        <p>
          Phone screen configurations are configured in Organization settings by admins. They consist of the calibration
          criteria, candidate level and duration of a phone screen event. Multiple configurations can be used by an
          Organization where there are different calibration requirements or durations for different roles.
        </p>
      </div>
    ),
    links: [WHAT_IS_ELEVATE],
  },
  findLoopShadowOpportunitiesTable: {
    title: 'Loop shadow opportunities',
    content: (
      <div>
        <p>A table of all upcoming Loop events which have available shadow slots.</p>
        <p>
          Shadows will see all events for which they are eligible to shadow Interviewers. Eligibility is based on Calibration
          status as well as Interviewer level.
        </p>
        <p>
          Shadows can claim and release claims on any Interviews in this table. If an event is &apos;locked&apos;, shadows
          can no longer claim or release slots for this event.
        </p>
      </div>
    ),
    links: [WHAT_IS_ELEVATE],
  },
  findLoopOpportunitiesTable: {
    title: 'Loop opportunities',
    content: (
      <div>
        <p>A table of all upcoming Loop events which require a calibrated Interviewer to sign up.</p>
        <p>
          Interviewers will see all events for which they are eligible to lead Interviews. Eligibility is based on
          Calibration status as well as Interviewer level.
        </p>
        <p>
          Interviewers can claim and release claims on any Interviews in this table. If an event is &apos;locked&apos;,
          Interviewers can no longer claim or release Interview slots.
        </p>
      </div>
    ),
    links: [WHAT_IS_ELEVATE],
  },
  mainLoopLoopTable: {
    title: 'Loop events',
    content: (
      <div>
        <p>
          A table of all upcoming Loop events in this Organization. Loop events can be selected and edited here. Each record
          will outline the contacts taking part in a loop event.
        </p>
        <p>
          The candidates column will show an icon for each candidate that has been added to an event up to a maximum of 4.
        </p>
        <p>The readiness column will be ‘at risk’ when the required interviewer roles are unfilled.</p>
        <p>To see further details about a loop event, select a record in the table and a split panel will appear.</p>
      </div>
    ),
    links: [WHAT_IS_ELEVATE],
  },
  mainPhoneScreenPhoneScreenTable: {
    title: 'Phone screens',
    content: (
      <div>
        <p>
          A table of all upcoming phone screens in this Organization. Phone screens can be selected and edited here. Each
          record will outline the contacts taking part in the event, along with the candidate info.
        </p>
      </div>
    ),
    links: [WHAT_IS_ELEVATE],
  },
  profileLoopLoopTable: {
    title: 'Loop events',
    content: (
      <div>
        <p>
          A table of all upcoming Loop events that the Interviewer is participating in. Events that you are Interviewing,
          shadowing or reverse shadowing will show in this table.
        </p>
        <p>To see further details about a loop event, select a record in the table and a split panel will appear.</p>
        <h4>Candidates</h4>
        <p>
          The candidates column will show an icon for each candidate that has been added to an event up to a maximum of 4.
        </p>
        <h4>Readiness</h4>
        <p>The readiness column will be &apos;at risk&apos; when the required interviewer roles are unfilled.</p>
      </div>
    ),
    links: [WHAT_IS_ELEVATE],
  },
  profilePhoneScreenPhoneScreenTable: {
    title: 'Phone screens',
    content: (
      <div>
        <p>
          A table of all upcoming Phone Screen events that the Interviewer is participating in. Events that you are
          Interviewing, shadowing or reverse shadowing will show in this table.
        </p>
      </div>
    ),
    links: [WHAT_IS_ELEVATE],
  },
  profileCalibrationAndTrainingPhoneScreenCalibration: {
    title: 'Phone screen calibration',
    content: (
      <div>
        <p>&apos;Not calibrated&apos; means the Interviewer has not yet been added to a calibration cohort.</p>
        <p>
          &apos;In calibration&apos; means the Interviewer has been added to a calibration cohort and will see their
          progress.
        </p>
        <p>
          &apos;Independent&apos; means the Interviewer has met the calibration requirements and can now Interview
          independently, without a shadow.
        </p>
        <p>
          &apos;Calibrated&apos; means the Interviewer is fully calibrated and can be shadowed by other Interviewers whom are
          &apos;In calibration&apos;.
        </p>
      </div>
    ),
    links: [WHAT_IS_ELEVATE],
  },
  profileCalibrationAndTrainingLoopCalibration: {
    title: 'Loop calibration',
    content: (
      <div>
        <p>&apos;Not calibrated&apos; means the Interviewer has not yet been added to a calibration cohort.</p>
        <p>
          &apos;In calibration&apos; means the Interviewer has been added to a calibration cohort and will see their
          progress.
        </p>
        <p>
          &apos;Independent&apos; means the Interviewer has met the calibration requirements and can now Interview
          independently, without a shadow.
        </p>
        <p>
          &apos;Calibrated&apos; means the Interviewer is fully calibrated and can be shadowed by other Interviewers whom are
          &apos;In calibration&apos;.
        </p>
      </div>
    ),
    links: [WHAT_IS_ELEVATE],
  },
  profileMyAvailabilityMyPhoneScreenAvailabilityTable: {
    title: 'Availability',
    content: (
      <div>
        <p>
          Create availability windows that can be used by recruitment to offer to candidates and schedule a Phone Screen. You
          can enter single availability windows or recurring availability windows. You can also edit or remove slots as long
          as they are in &apos;available&apos; state. If they are &apos;requested&apos;, then they have already been offered
          to a candidate. If they are &apos;claimed&apos; they have already been used to schedule a phonescreen.
        </p>
        <h4>Start time</h4>
        <p>Time availability begins</p>
        <h4>End time</h4>
        <p>Time availability ends</p>
        <h4>TimeZone</h4>
        <p>Timezone for the availability window</p>
        <h4>End date</h4>
        <p>Date that the availability series ends</p>
        <h4>Days of the week</h4>
        <p>The days of the week for which this availability series applies.</p>
      </div>
    ),
    links: [WHAT_IS_ELEVATE],
  },
  profileMyAvailabilitySkillProficiences: {
    title: 'Skill proficiences',
    content: (
      <div>
        <p>
          An interviewer&apos;s technical proficiencies. These proficiencies are used to match interviewers with technically
          relevant loop and phone screen events.
        </p>
      </div>
    ),
    links: [WHAT_IS_ELEVATE],
  },
  loopCreateLoopConfiguration: {
    title: 'Loop configuration',
    content: (
      <div>
        <p>
          Loop configurations are defined at the Organization level. They determine a Loop event&apos;s per-level interviewer
          pool limits, calibration requirements, and participant type requirements.
        </p>
      </div>
    ),
    links: [WHAT_IS_ELEVATE],
  },
  eventOptionsAdditionalSettings: {
    title: 'Loop configuration',
    content: (
      <div>
        <p>
          The requirements of a loop event may prohibit the lead or shadow interviewers from participating in the loop
          events.
        </p>
      </div>
    ),
    links: [WHAT_IS_ELEVATE],
  },
  editContactsForLoopEvent: {
    title: 'Contacts',
    content: (
      <div>
        <p>
          Configure these contacts to control the event lead, client lead, and recruiting coordinator for the loop event.
        </p>
        <h4>Event Lead</h4>
        <p>
          Responsible for creating and owning the Loop event. They create the event based on their availability and select
          the configuration for the Loop which will specify which Interviewer roles and requirements. Typically this is the
          Hiring Manager and they will participate in the Hiring Manager Interview slot.
        </p>
        <h4>Client Lead</h4>
        <p>
          Responsible for owning the Candidate journey through the loop. The Client lead has visibility of events that they
          are added to. They also forecast the required number of loop events based on the recruitment pipeline.
        </p>
        <h4>Recruiting Coordinator</h4>
        <p>
          Responsible for offering Loop candidate slots to candidates. Recruitment Coordinators (RC) will add candidates to a
          loop and mark an event as locked once it has the required number of participants. The RC will then own scheduling
          the event in Hire and sending the invites to the Interviewers and Candidates.
        </p>
      </div>
    ),
    links: [WHAT_IS_ELEVATE],
  },
  editContactsForPhoneScreen: {
    title: 'Contacts',
    content: (
      <div>
        <p>Configure the contact to control the recruiting coordinator for the phone screen.</p>
        <h4>Recruiting Coordinator</h4>
        <p>
          Responsible for offering Loop candidate slots to candidates. Recruitment Coordinators (RC) will add candidates to a
          loop and mark an event as locked once it has the required number of participants. The RC will then own scheduling
          the event in Hire and sending the invites to the Interviewers and Candidates.
        </p>
      </div>
    ),
    links: [WHAT_IS_ELEVATE],
  },
  editCandidatesForLoop: {
    title: 'Candidates',
    content: (
      <div>
        <p>
          Assign candidates to a loop event using these fields, limited by the maximum number of candidates defined above.
        </p>
        <p>
          Candidates can be added to an event after creation. Typically a Recruitment Coordinator will add candidates to a
          Loop event after the event has been created by an Event Lead.
        </p>
        <h4>Hire link</h4>
        <p>Enter the URL for the Hire profile for this candidate.</p>
        <h4>Location</h4>
        <p>Use an airport code to set a candidate&apos;s location.</p>
      </div>
    ),
    links: [WHAT_IS_ELEVATE],
  },
  editCandidatesForPhoneScreen: {
    title: 'Candidate',
    content: (
      <div>
        <p>Enter the name, Hire link, location and level of the candidate.</p>
      </div>
    ),
    links: [WHAT_IS_ELEVATE],
  },
  editInterviewersForLoopEvent: {
    title: 'Interviewers',
    content: (
      <div>
        <p>
          Assign interviewers to a loop event using these fields, defined by the loop configuration assigned to this loop
          event.
        </p>
        <p>
          Interviewers can self sign up after an event has been created. Interviewers can also be added by an Event lead or
          Recruitment Coordinator manually if required.
        </p>
      </div>
    ),
    links: [WHAT_IS_ELEVATE],
  },
  editInterviewersForPhoneScreen: {
    title: 'Interviewers',
    content: (
      <div>
        <p>
          Assign the interviewer and shadow (optional) to a phone screen using these fields, defined by the assigned phone
          screen configuration.
        </p>
      </div>
    ),
    links: [WHAT_IS_ELEVATE],
  },
  organizationCollaborationsInboundCollaborationsTable: {
    title: 'Inbound collaborations',
    content: (
      <div>
        <p>An inbound collaboration allows your interviewers to participate in another organizations events.</p>
        <p>
          Accept a collaboration invitation sent from another ElevateHire Organization. This mechanism allows you to share
          Bar raisers, shadow opportunities and Interviewer opportunities with other Organizations.
        </p>
      </div>
    ),
    links: [WHAT_IS_ELEVATE],
  },
  organizationCollaborationsOutboundCollaborationsTable: {
    title: 'Outbound collaborations',
    content: (
      <div>
        <p>An outbound collaboration allows another organizations interviewers to participate in your events.</p>
        <p>
          Set up a collaboration between this ElevateHire Organization and others. This mechanism allows you to share Bar
          raisers, shadow opportunities and Interviewer opportunities with other Organizations.
        </p>
        <p>Collaborations must be approved by the Organization receiving the invite.</p>
        <h4>Organization</h4>
        <p>
          Choose the target Organization to collaborate with. The current Organization and any existing collaborating
          Organizations will not appear in this list.
        </p>
        <h4>Bar raiser list</h4>
        <p>Specify whether you will share a Bar raiser list with this Organization.</p>
        <h4>Loops</h4>
        <p>
          Share loop events between Organizations by mapping participant roles. At least one mapping is required to share
          events.
        </p>
        <h4>Phone Screens</h4>
        <p>
          Share phone screen events between Organizations by mapping participant roles. At least one mapping is required to
          share events.
        </p>
      </div>
    ),
    links: [WHAT_IS_ELEVATE],
  },
  organizationLoopConfigurationsLoopConfigurationsTable: {
    title: 'Loop configurations',
    content: (
      <div>
        <p>
          Create a configuration for loop events. The configuration will consist of participant roles which will be assumed
          by Interviewers for the loop event, as well as the candidate levels that this configuration will apply to. This
          allows you to set different participant roles depending on the candidates levels if required. For example, level 4
          candidates will have different participants in a loop than a level 7 candidate.
        </p>
        <h4>Candidate levels</h4>
        <p>Define the candidate levels to which this configuration is valid.</p>
        <h4>Duration</h4>
        <p>
          Specify the duration of a Phone Screen. Interviewers will be unable to create availability windows shorter than
          this value.
        </p>
        <h4>Participants</h4>
        <p>Select Participant role(s) that interview in this configuration.</p>
        <h4>Bar raiser</h4>
        <p>Specify whether the bar raiser is required and whether they should attend the Interview or just the debrief.</p>
      </div>
    ),
    links: [WHAT_IS_ELEVATE],
  },
  organizationLoopConfigurationParticipantsTable: {
    title: 'Participants',
    content: (
      <div>
        <p>
          Define a participant role that an Interviewer will assume for a phone screen. This is then connected to a
          calibration criteria to set calibration requirements for the participant.
        </p>
        <h4>Calibration config</h4>
        <p>
          Attach this participant role to a calibration criteria. This will define how many shadows, reverse shadows and
          shepherd reviews the participant is required to complete before they can become calibrated.
        </p>
      </div>
    ),
    links: [WHAT_IS_ELEVATE],
  },
  organizationPhoneScreenConfigurationsPhoneScreenConfigurationsTable: {
    title: 'Phone screen configurations',
    content: (
      <div>
        <p>
          Create a configuration for phone screen events. The configuration will consist of a participant role which will be
          assumed by an Interviewer for the phone screen as well as the candidate levels that this configuration will apply
          to. This allows you to set different participant roles depending on the candidates level, if required.
        </p>
        <h4>Candidate levels</h4>
        <p>Define the candidate levels to which this configuration is valid.</p>
        <h4>Duration</h4>
        <p>
          Specify the duration of a Phone Screen. Interviewers will be unable to create availability windows shorter than
          this value.
        </p>
        <h4>Participants</h4>
        <p>Select Participant role(s) that interview in this configuration.</p>
      </div>
    ),
    links: [WHAT_IS_ELEVATE],
  },
  organizationPhoneScreenConfigurationParticipantsTable: {
    title: 'Participants',
    content: (
      <div>
        <p>
          Define a participant role that an Interviewer will assume for a phone screen. This is then connected to a
          calibration criteria to set calibration requirements for the participant.
        </p>
        <h4>Calibration config</h4>
        <p>
          Attach this participant role to a calibration criteria. This will define how many shadows, reverse shadows and
          shepherd reviews the participant is required to complete before they can become calibrated.
        </p>
      </div>
    ),
    links: [WHAT_IS_ELEVATE],
  },
  organizationLoopConfigurationCalibrationsTable: {
    title: 'Calibrations',
    content: (
      <div>
        <p>
          Create a calibration criteria and specify the number of shadows, reverse shadows and shepherd reviews required to
          achieve calibration. This can be connected to a participant to define the calibration requirements for said
          participant.
        </p>
        <h4>Minimum shadows required</h4>
        <p>
          Specify the minimum number of shadows required before an Interviewer can request reverse shadow calibration status.
        </p>
        <h4>Minimum reverse shadows required</h4>
        <p>
          Specify the minimum number of reverse shadows required before an interviewer can request Independent calibration
          status.
        </p>
        <h4>Minimum calibration shepherd reviews</h4>
        <p>
          Optional. Specifies the number of calibration shepherd reviews required before an Interviewer can be marked as
          fully calibrated.
        </p>
        <h4>Participant definition</h4>
        <p>
          Attach a participant role that this calibration criteria will apply to. This action can also be performed when
          creating or editing participant roles.
        </p>
      </div>
    ),
    links: [WHAT_IS_ELEVATE],
  },
  organizationPhoneScreenConfigurationCalibrationsTable: {
    title: 'Calibrations',
    content: (
      <div>
        <p>
          Create a calibration criteria and specify the number of shadows, reverse shadows and shepherd reviews required to
          achieve calibration. This can be connected to a participant to define the calibration requirements for said
          participant.
        </p>
        <h4>Minimum shadows required</h4>
        <p>
          Specify the minimum number of shadows required before an Interviewer can request reverse shadow calibration status.
        </p>
        <h4>Minimum reverse shadows required</h4>
        <p>
          Specify the minimum number of reverse shadows required before an interviewer can request Independent calibration
          status.
        </p>
        <h4>Minimum calibration shepherd reviews</h4>
        <p>
          Optional. Specifies the number of calibration shepherd reviews required before an Interviewer can be marked as
          fully calibrated.
        </p>
        <h4>Participant definition</h4>
        <p>
          Attach a participant role that this calibration criteria will apply to. This action can also be performed when
          creating or editing participant roles.
        </p>
      </div>
    ),
    links: [WHAT_IS_ELEVATE],
  },
  organizationTrainingsList: {
    title: 'Trainings',
    content: (
      <div>
        <p>
          Add KNET trainings whether they are a pre-requisite for Interviewers before taking part in an Event. You can You
          can also set a minimum accepted version for the training.
        </p>
        <p>Trainings can be activated or deactivated and marked as a requirement at any time after creation.</p>
      </div>
    ),
    links: [WHAT_IS_ELEVATE],
  },
  organizationDefaultShepherdSetting: {
    title: 'Default shepherd assignment',
    content: (
      <div>
        <p>
          The default calibration shepherd assignment determines what action the system will perform when a person moves into
          the organization.
        </p>
      </div>
    ),
    links: [WHAT_IS_ELEVATE],
  },
  organizationCalibrationShepherdTable: {
    title: 'Calibration shepherds',
    content: (
      <div>
        <p>
          Calibration Shepherds are responsible for an Interviewers calibration journey from Shadow to active Interviewer.
          They have the ability to access their assigned Interviewers profiles and update their Calibration status.
        </p>
        <p>Adding a Shepherd here makes them available in the assignments table.</p>
      </div>
    ),
    links: [WHAT_IS_ELEVATE],
  },
  organizationCalibrationShepherdAssignmentsTable: {
    title: 'Calibration shepherd assignments',
    content: (
      <div>
        <p>
          Assign Interviewers to a Calibration shepherd. Interviewers can have multiple shepherds. Shepherds must be added to
          the &apos;Calibration shepherds&apos; table before they are available for assignment here.
        </p>
      </div>
    ),
    links: [WHAT_IS_ELEVATE],
  },
  organizationSection: {
    title: 'Organization selection',
    content: <div>The organization you wish to manipulate ElevateHire loop event and phone screen resources within.</div>,
    links: [WHAT_IS_ELEVATE],
  },
  timezoneSection: {
    title: 'Timezone selection',
    content: (
      <div>
        The timezone defaults to your browser by default. You can select another time zone if it is more appropriate.
      </div>
    ),
    links: [WHAT_IS_ELEVATE],
  },
  default: {
    title: 'Elevate',
    content: (
      <div>
        Elevate improves hiring by streamlining the process of managing loop events and phone screens; mechanizing the
        process of shepherding interviewers through the calibration process.
      </div>
    ),
    links: [
      WHAT_IS_ELEVATE,
      {
        href: 'https://w.amazon.com/bin/view/GTA_Learning_and_Development/MGHD_FAQS/',
        text: 'Make Great Hiring Decisions',
      },
      {
        href: 'https://inside.hr.amazon.dev/us/en/employment/hiring-and-recruiting/making-great-hiring-decisions/hiring-process.html',
        text: '10 Essentials of Interviewing',
      },
      {
        href: 'https://amzn-aws.slack.com/archives/C01UKFNH21Y',
        text: 'Slack',
      },
      {
        href: 'mailto:aws-elevatehire-team@amazon.com',
        text: 'Email',
      },
      {
        href: 'https://w.amazon.com/bin/view/ElevateHire/Contact/',
        text: 'Ticket',
      },
    ],
  },
} satisfies Record<string, ElevateHelpContent>;
