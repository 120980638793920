import { ConsoleLogger } from 'aws-amplify/utils';
import type { GraphQLError } from 'graphql';
import isEqual from 'lodash/isEqual';
import { useEffect, useRef } from 'react';

import type { Nullable, OptionalString } from '@/common/models';

const logger = new ConsoleLogger('utils');

const VALID_URL_REGEX = new RegExp(String.raw`(https?://)?([\da-z.-]+)\.([a-z.]{2,6})[/\w .-]*/?`);
const VALID_HIRE_URL_REGEX = new RegExp('(http|https)://hire.(corp.|)amazon.com/.*$');

export function urlFormatValidation(url: OptionalString): boolean {
  return VALID_URL_REGEX.test(url ?? '');
}

export function hireUrlFormatValidation(url: OptionalString): boolean {
  return VALID_HIRE_URL_REGEX.test(url ?? '');
}

export function dedupeList<T>(items: T[], idKey: keyof T): T[] {
  return [...new Map(items.map((item) => [item[idKey], item])).values()];
}

export function dedupeAndFlattenList<T>(items: T[][], idKey: keyof T): T[] {
  return dedupeList(items.flat(1), idKey);
}

export function listsMatch<T extends object>(records1: T[], records2: T[], key: keyof T): boolean {
  if (records1.length !== records2.length) return false;
  const recordIds1 = records1.map((record) => record[key]);
  const recordIds2 = records2.map((record) => record[key]);
  return recordIds1.every((ptxId) => recordIds2.includes(ptxId)) && recordIds2.every((ptxId) => recordIds1.includes(ptxId));
}

export function idListsMatch(itemIds1: string[], itemIds2: string[]) {
  if (itemIds1.length !== itemIds2.length) return false;
  return itemIds1.every((id) => itemIds2.includes(id)) && itemIds2.every((id) => itemIds1.includes(id));
}

export function flattenMapValues<K, V>(map: Map<K, V>): Set<V> {
  return new Set<V>(map.values());
}

export function useStableValue<T>(value: T) {
  const ref = useRef<T>(value);

  const isIdentical = ref.current && isEqual(value, ref.current);

  useEffect(() => {
    if (!isIdentical) ref.current = value;
  }, [isIdentical, value]);

  return isIdentical ? ref.current : value;
}

export function getErrorMessageFromException(ex: unknown): string {
  let errorMsg: string;
  if (typeof ex === 'object' && ex && 'errors' in ex) {
    errorMsg = Array.isArray(ex.errors) ? (ex.errors as GraphQLError[])[0].message || '' : '';
  } else {
    errorMsg = JSON.stringify(ex);
  }
  // For local dev work log all the things
  if (import.meta.env.MODE === 'development') logger.info(ex);
  return errorMsg;
}

export function mapToObject<T>(map: Map<string, T>): { [key: string]: T } {
  const object = {};

  for (const [key, value] of map) {
    object[key] = value;
  }
  return object;
}

export function coerceNumber(value: Nullable<string | number>, fallback = 0): number {
  const newValue = typeof value === 'number' ? value : Number.parseInt(value ?? '');
  return Number.isNaN(newValue) ? fallback : newValue;
}

export function boundedNumber(value: number, min: Nullable<string | number>, max: Nullable<string | number>): number {
  const minValue = coerceNumber(min, 0);
  const maxValue = coerceNumber(max, Number.MAX_SAFE_INTEGER);
  // A+ to CodeWhisperer for thinking of this before I did
  return Math.min(Math.max(value, minValue), maxValue);
}

export function compareByKey<T extends Record<string, Nullable<unknown>>>(
  a: T,
  b: T,
  key: keyof T,
  reverse = false
): number {
  const v1 = (reverse ? b[key] : a[key]) ?? '';
  const v2 = (reverse ? a[key] : b[key]) ?? '';
  return v1 === v2 ? 0 : v1 > v2 ? 1 : -1;
}

export function sortByKey<T extends Record<string, unknown>>(value: T[], key: keyof T, mode?: 'asc' | 'desc'): T[] {
  return value.sort((a, b) => compareByKey(a, b, key, mode === 'desc'));
}

export function cleanPermissionGroup(value: string): string {
  return value.trim().replace('amzn1.abacus.team.', '');
}
