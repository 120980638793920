import Box from '@amzn/awsui-components-react/polaris/box';
import Container from '@amzn/awsui-components-react/polaris/container';
import Spinner, { SpinnerProps } from '@amzn/awsui-components-react/polaris/spinner';

type TypedSpinnerParams = {
  align?: 'center' | 'left' | 'right';
  size: SpinnerProps.Size;
};

/**
 * Containerized version of the Polaris `Spinner` component.
 * Spinner is positioned within a `Container` so it can be used on pages and support modular loading.
 */
const ContainerizedSpinner = ({ size }: TypedSpinnerParams): JSX.Element => (
  <Container>
    <Spinner size={size} />
  </Container>
);

/**
 * Full-page version of the Polaris `Spinner` component.
 * This version includes styling overrides to force the spinner to middle of the page
 */
const FullPageSpinner = ({ size }: TypedSpinnerParams): JSX.Element => (
  <div style={{ textAlign: 'center', position: 'absolute', top: '50%', left: '50%' }}>
    <Spinner size={size} variant="normal" />
  </div>
);

const InlineSpinner = ({ size, align }: TypedSpinnerParams): JSX.Element => (
  <Box textAlign={align}>
    <Spinner size={size} variant="normal" />
  </Box>
);

type ElevateSpinnerParams = {
  align?: 'center' | 'left' | 'right';
  size?: SpinnerProps.Size;
  variant?: 'full-page' | 'container' | 'inline';
};

/**
 * A purpose-built version of the polaris `Spinner` component.
 * Spinner can be returned for use on a full page (i.e. while logging in) or w/in
 * a container while data in a module of a page is loading.
 *
 * See also: {@link https://refresh.polaris.a2z.com/components/spinner/?tabId=api Spinner}
 */
const ElevateSpinner = ({ variant = 'inline', size = 'large', align = 'center' }: ElevateSpinnerParams): JSX.Element => {
  switch (variant) {
    case 'full-page':
      return <FullPageSpinner size={size} />;
    case 'container':
      return <ContainerizedSpinner size={size} />;
    default:
      return <InlineSpinner size={size} align={align} />;
  }
};

export default ElevateSpinner;
