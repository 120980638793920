import Box from '@amzn/awsui-components-react/polaris/box';
import Button, { type ButtonProps } from '@amzn/awsui-components-react/polaris/button';
import Header from '@amzn/awsui-components-react/polaris/header';
import Link, { type LinkProps } from '@amzn/awsui-components-react/polaris/link';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';

import {
  externalLinkProps,
  getHeaderCounterText,
  getReadableFromEnum,
  getServerHeaderCounterText,
  pluralize,
} from '@/common/labels';
import type { OptionalString } from '@/common/models';
import { HIRE_ID_REGEX } from '@/utilities/constants';
import { isValidUUID } from '@/utilities/uuid-encoder';

type IExternalLinkItem = {
  showIcon?: boolean;
  fontSize?: LinkProps.FontSize;
  href: OptionalString;
  text: OptionalString;
  hoverText?: OptionalString;
};

export const ExternalLinkItem = ({ href, text, fontSize, showIcon, hoverText }: IExternalLinkItem): React.ReactElement => (
  <span title={hoverText || 'Open in new tab'}>
    <Link
      external={showIcon}
      href={href ?? undefined}
      fontSize={fontSize}
      ariaLabel={`${text} ${externalLinkProps.externalIconAriaLabel}`}
      target="_blank"
    >
      {text ?? '-'}
    </Link>
  </span>
);

type IInfoLink = {
  label?: OptionalString;
  onFollow: (event: CustomEvent<{ href: string | undefined; external?: boolean; target?: string }>) => void;
};

export const InfoLink = ({ label, onFollow }: IInfoLink): React.ReactElement => (
  <Link variant="info" onFollow={onFollow}>
    {`${label || 'Info'}`}
  </Link>
);

type ITableCounter = {
  counter?: OptionalString;
  selectedItems?: unknown[];
  serverSide?: boolean;
  totalItems: unknown[];
};

function getCounter({ counter, serverSide, totalItems, selectedItems }: ITableCounter): string {
  if (counter) return counter;
  if (serverSide) {
    return getServerHeaderCounterText(totalItems, selectedItems);
  }
  return getHeaderCounterText(totalItems, selectedItems);
}

export type ITableHeader = {
  actionButtons?: React.ReactNode;
  description?: OptionalString;
  info?: () => void;
  title: OptionalString;
} & ITableCounter;

export const TableHeader = ({
  actionButtons,
  description,
  info,
  title,
  ...counterParams
}: ITableHeader): React.ReactElement => (
  <Header
    counter={getCounter(counterParams)}
    info={!!info && <InfoLink onFollow={info} />}
    description={description}
    actions={actionButtons}
  >
    {title}
  </Header>
);

type ITableNoMatchState = {
  onClearFilter?: () => void;
};

export const TableNoMatchState = ({ onClearFilter }: ITableNoMatchState): React.ReactElement => (
  <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
    <SpaceBetween size="xxs">
      <div>
        <b>No matches</b>
        <Box variant="p" color="inherit">
          We can&apos;t find a match.
        </Box>
      </div>
      <Button onClick={onClearFilter}>Clear filter</Button>
    </SpaceBetween>
  </Box>
);

type ITableEmptyState = {
  resourceName: string;
  parentResource?: OptionalString;
  showButton?: boolean;
  onClick?: (event: CustomEvent<ButtonProps.ClickDetail>) => void;
};

export const TableEmptyState = ({
  resourceName,
  onClick,
  parentResource = 'resource',
  showButton = true,
}: ITableEmptyState): React.ReactElement => (
  <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
    <SpaceBetween size="xxs">
      <Box variant="div">
        <b>No {pluralize(getReadableFromEnum(resourceName))}</b>
        <Box variant="p" color="inherit">
          No {pluralize(getReadableFromEnum(resourceName))} associated with this {parentResource}.
        </Box>
      </Box>
      {showButton && <Button onClick={onClick}>Create {resourceName.toLowerCase()}</Button>}
    </SpaceBetween>
  </Box>
);

export function getHireIdDisplay(value: OptionalString): string {
  let displayValue = value?.match(HIRE_ID_REGEX)?.at(0);
  if (!displayValue && value) {
    const hireId = value.split('/').at(-1);
    displayValue = isValidUUID(hireId) ? hireId.slice(0, 8) : undefined;
  }
  return displayValue ?? '';
}
