import type { SelectProps } from '@amzn/awsui-components-react/polaris/select';
import { DateTime, IANAZone, type Zone } from 'luxon';

import type { OptionalString } from '@/common/models';
import abbreviationsJson from '@/resources/timezone/abbreviations.json';
import timezones from '@/resources/timezone/amzn-time-zones.json';

type ZoneAbbreviation = typeof abbreviationsJson;
const abbreviations: ZoneAbbreviation = abbreviationsJson;

export type AmznMeetingTimezone = {
  alternativeIDs: string[];
  displayName: string;
  id: string;
  standardLongName: string;
  standardShortName?: string;
};

export type TimezoneOption = SelectProps.Option & {
  id: string;
  baseName: string;
  offsetMin: number;
};

const amazonTimezones: AmznMeetingTimezone[] = timezones;

export function getTimezoneAbbreviation(epochMilis: number, zone: IANAZone | Zone): string {
  /*
    Use Luxon to get the time zone 'long' name. i.e. Eastern Standard Time.
    Use the long name to look up the abbreviation. We can do this w/ Luxon, but
    the value returned is locale-based and unpredictable. So using a preset list ensures consistency.

    The value is normalized based on the epoch date passed in.
      Ex: America/New_York Timezone - in December this returns Eastern Standard Time, and Eastern Daylight Time in July
  */
  const fullTimezoneName = zone.offsetName(epochMilis, { format: 'long' }) ?? '';
  let abbr: OptionalString = abbreviations[fullTimezoneName];
  if (!abbr) {
    // For locales where only 1 tz is used, the abbreviations data omits the 'Daylight' / 'Standard'
    // modifier, so we strip it here to ensure a match.
    abbr = abbreviations[fullTimezoneName.replace('Standard ', '').replace('Daylight ', '')];
  }
  // If we got this far and no luck, fall back to the Intl abbreviation from Luxon
  if (!abbr) abbr = zone.offsetName(epochMilis, { format: 'short' });
  // sanity check in case we only got an offset
  if (abbr && ['+', '-'].includes(abbr.charAt(0))) abbr = 'UTC' + abbr;
  return abbr ?? '';
}

/** Normalizes and sorts the list of timezones from the Amazon meetings API. */
export function getFilteredTimezones(date?: OptionalString): TimezoneOption[] {
  // Luxon only returns epoch time as seconds and accepts it as milliseconds ...idky
  const nowUnix = date ? DateTime.fromISO(date).toUnixInteger() * 1000 : DateTime.now().toUnixInteger() * 1000;

  return amazonTimezones
    .map((timezone) => {
      // initialize a full timezone object from the ID
      const zone = new IANAZone(timezone.id);
      const abbr = getTimezoneAbbreviation(nowUnix, zone);

      // Extracts UTC offset - this is based on the current date passed in.
      // Ex: America/Los_Angeles Timezone - in December this returns -08:00, and -07:00 in July
      const offset = zone.formatOffset(nowUnix, 'short');
      return {
        label: '(UTC' + offset + ') ' + timezone.displayName.slice(timezone.displayName.indexOf(') ') + 2),
        labelTag: abbr,
        offsetMin: zone.offset(nowUnix),
        baseName: timezone.displayName.slice(timezone.displayName.indexOf(') ') + 2),
        id: timezone.id,
        filteringTags: [...timezone.alternativeIDs.map((id) => id.toLowerCase()), timezone.id.toLowerCase()],
      };
    })
    .sort((a, b) => {
      // Sort by UTC-offset ASC and name ASC
      if (a.offsetMin === b.offsetMin) return a.baseName > b.baseName ? 1 : -1;
      return a.offsetMin > b.offsetMin ? 1 : -1;
    });
}
