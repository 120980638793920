import { Roles } from '@amzn/elevate-graphql/types';
import { useCallback, useMemo } from 'react';

import { ElevateAuthSession, useCognitoSession } from '@/backend/auth/cognito-auth';
import type { Nullable } from '@/common/models';
import { useSession } from '@/stores/SessionConfig';
import featureFlags from '@/utilities/feature-flags';
import { type AuthAction, checkConditionalAuth, checkPermissionMatrix } from '@/utilities/permissions';

function useHasElevateAccess(session: Nullable<ElevateAuthSession>): boolean {
  return useMemo(() => !!session?.validElevateUser, [session?.validElevateUser]);
}

function useHasAdminAccess(session: Nullable<ElevateAuthSession>): boolean {
  const hasElevateAccess = useHasElevateAccess(session);
  return useMemo(
    () => hasElevateAccess && !!session?.isElevateAdmin && session.groups.includes(Roles.ELEVATE_ADMIN),
    [hasElevateAccess, session?.groups, session?.isElevateAdmin]
  );
}

function useHasOrgAccess(session: Nullable<ElevateAuthSession>): boolean {
  const hasElevateAccess = useHasElevateAccess(session);
  return useMemo(() => {
    const groups = (session?.groups ?? []).filter((g) => !g.endsWith('_profile'));

    return hasElevateAccess && !!groups.length;
  }, [hasElevateAccess, session?.groups]);
}

function useHasPromoHubAccess(session: Nullable<ElevateAuthSession>): boolean {
  const hasElevateAccess = useHasElevateAccess(session);
  return useMemo(() => hasElevateAccess && featureFlags.PROMOHUB_ENABLED.value, [hasElevateAccess]);
}

function useHasQuestionBankAccess(session: Nullable<ElevateAuthSession>): boolean {
  const hasElevateAccess = useHasElevateAccess(session);
  return useMemo(() => hasElevateAccess && featureFlags.QUESTION_BANK_ENABLED.value, [hasElevateAccess]);
}

function useCheckUserPermission(session: Nullable<ElevateAuthSession>) {
  const { sessionObject } = useSession();
  const hasElevateAccess = useHasElevateAccess(session);
  const hasAdminAccess = useHasAdminAccess(session);

  return useCallback(
    (action: AuthAction, conditionalAuthCallback?: () => boolean) => {
      if (!(session && sessionObject.activeOrgID && hasElevateAccess)) return false;
      if (hasAdminAccess) return true;
      if (!sessionObject.orgPermissions) return false;
      const activeOrgGroups = sessionObject.orgPermissions.get(sessionObject.activeOrgID);

      const hasUnconditionalAuth = session.groups.some((group) =>
        [...(activeOrgGroups?.get(group) ?? [])].some((group) => checkPermissionMatrix(action, group))
      );

      return hasUnconditionalAuth || checkConditionalAuth(action, conditionalAuthCallback);
    },
    [hasAdminAccess, hasElevateAccess, session, sessionObject.activeOrgID, sessionObject.orgPermissions]
  );
}

export function useCognitoAuth() {
  const { data } = useCognitoSession();
  const hasElevateAccess = useHasElevateAccess(data);
  const hasOrgAccess = useHasOrgAccess(data);
  const hasPromoHubAccess = useHasPromoHubAccess(data);
  const hasQuestionBankAccess = useHasQuestionBankAccess(data);
  const hasAdminAccess = useHasAdminAccess(data);
  const hasAccess = useCheckUserPermission(data);

  return {
    hasAccess,
    hasAdminAccess,
    hasElevateAccess,
    hasOrgAccess,
    hasPromoHubAccess,
    hasQuestionBankAccess,
    username: data?.username,
    firstname: data?.firstName,
    lastname: data?.lastName,
    groups: data?.groups,
  };
}
