import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { STATIC_ROUTES } from '@/utilities/constants';

type IRouteGuard = {
  isAllowed: boolean;
  children?: React.ReactNode;
};

const RouteGuard = ({ isAllowed, children }: IRouteGuard): React.ReactElement => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAllowed) navigate(STATIC_ROUTES.ACCESS_DENIED, { relative: 'route' });
  }, [isAllowed, navigate]);

  return <>{children ?? <Outlet />}</>;
};

export default RouteGuard;
