import { ElevateDateTime } from '@/common/date-time-helpers';
import type { Nullable, OptionalBool, OptionalNumber, OptionalString } from '@/common/models';
import type { CandidateDefinition } from '@/components/Candidate/models';
import {
  AvailabilityState,
  EventParticipantMode,
  EventStatus,
  EventType,
  InterviewerRole,
  LoopConfigBrMode,
  OrgConfigDefinitionType,
  RecursionInterval,
  Roles,
} from '@/graphql/types';
import type { LoopRoutingAttempt } from '@/models';

export type EventActions = 'create' | 'edit' | 'lock' | 'unlock' | 'cancel';
export type EventConfigType = OrgConfigDefinitionType.LOOP_CONFIG | OrgConfigDefinitionType.PHONESCREEN_CONFIG;

export type BaseEventParticipant = {
  calibrationId?: OptionalString;
  description: string;
  id: string;
  name: string;
};

export type BaseEventConfig = {
  candidateLevels: number[];
  createdAt: string;
  description: OptionalString;
  breakDurationMinutes: OptionalNumber;
  durationMinutes: OptionalNumber;
  id: string;
  filterId: string;
  isActive: boolean;
  name: string;
  orgId: string;
  participants: Nullable<BaseEventParticipant[]>;
  filterParticipants?: Nullable<string[]>;
  statusValue: 'Active' | 'Inactive';
  updatedAt: string;
};

export type PhoneScreenConfig = {
  configType: OrgConfigDefinitionType.PHONESCREEN_CONFIG;
};

export type LoopEventConfig = {
  barRaiserMode: LoopConfigBrMode;
  configType: OrgConfigDefinitionType.LOOP_CONFIG;
  isPushRouting: boolean;
  maxShadows: OptionalNumber;
};

export type EventConfigDefinition = BaseEventConfig & (LoopEventConfig | PhoneScreenConfig);

export function isLoopConfig(v: Nullable<EventConfigDefinition>): v is LoopEventConfig & BaseEventConfig {
  return !!v && v.configType === OrgConfigDefinitionType.LOOP_CONFIG;
}

/**
 * Definition of an event contact. The definition's parameters are passed to the
 * `SelectProfile` component and used to render the list of users/profiles to choose from.
 *
 * Event contacts typically are:
 *  - `eventLead` - event lead
 *  - `recruitingPOC` - client lead
 *  - `designatedRC` - recruiting coordinator
 */
export type ContactDefinition = {
  /** Description for the field */
  description: string;
  /** Error text to apply to the field when content is invalid */
  errorText: string;
  /**
   * Key to pass to the `onChange` function when user selects a profile. The key is a field in the parent object.
   * Typical values: `eventLeadID`, `recruitingPOCID`, `designatedRCID`
   */
  fieldName: string;
  /** Label (title) for the `Select` field */
  label: string;
  /** The actual profileID `value` selected in the field */
  profileId: OptionalString;
  /** GraphQL filter to apply to query results (optional) */
  rawFilter?: object | undefined;
  includeRoles?: Roles[];
  excludeRoles?: Roles[];
};

export type EventInterviewerDefinition = {
  alias: string;
  id: OptionalString;
  isBarRaiser: boolean;
  isDirty: boolean;
  name: OptionalString;
  participantConfigId: OptionalString;
  profileId?: OptionalString;
  role: InterviewerRole;
  sourceOrgId?: OptionalString;
  timezoneId?: OptionalString;
  startDateTime?: OptionalString;
  endDateTime?: OptionalString;
  rating?: OptionalNumber;
  comment?: OptionalString;
};

export type InterviewSlot = {
  lead?: EventInterviewerDefinition;
  leadIndex?: number;
  shadow?: EventInterviewerDefinition;
  shadowIndex?: number;
  shadowEnabled?: boolean;
  participantId?: OptionalString;
  slotName: string;
  start?: ElevateDateTime;
  end?: ElevateDateTime;
};

type LoopDefinition = { eventType: EventType.LOOP };
type PhoneScreenDefinition = { eventType: EventType.PHONE_SCREEN };

export type EventDefinition = {
  id: OptionalString;
  orgId: OptionalString;
} & (LoopDefinition | PhoneScreenDefinition);

export type SignUpRestriction = 'Lead' | 'Shadow' | 'Lead & Shadow';

export type EventListItem = {
  candidateCount: number;
  candidates: Nullable<CandidateDefinition[]>;
  commentCount?: number;
  configName: OptionalString;
  createdAt: string;
  date: OptionalString;
  daysTillEvent: OptionalNumber;
  designatedRCAlias: OptionalString;
  designatedRCName: OptionalString;
  designatedRCID?: OptionalString;
  eventConfig: Nullable<EventConfigDefinition>;
  eventDateTime: string;
  eventLeadAlias: OptionalString;
  eventLeadName: OptionalString;
  eventReady: boolean;
  id: string;
  filterId: string;
  interviewers: Nullable<EventInterviewerDefinition[]>;
  routingHistory?: Nullable<LoopRoutingAttempt[]>;
  locked: boolean;
  orgId: string;
  filterOrgId: string;
  orgName: string;
  restrictLeadSignup?: OptionalBool;
  restrictShadowSignup?: OptionalBool;
  startTime: OptionalString;
  status: EventStatus | `${EventStatus}` | null;
  tags: Nullable<string[]>;
  updatedAt: string;
};

export type LoopEventListItem = EventListItem & {
  debriefBooked: OptionalString;
  debriefNote: OptionalString;
  eventType: EventType.LOOP;
  isVirtual?: OptionalBool;
  location?: string;
  lockedbyAlias?: OptionalString;
  lockedbyID?: OptionalString;
  maxCandidates: number;
  eventLeadId: OptionalString;
  recruitingPOCId: OptionalString;
  recruitingPOCName: OptionalString;
  recruitingPOCAlias: OptionalString;
  signUpRestriction?: SignUpRestriction;
};

export type LoopEventTemplate = Omit<
  LoopEventListItem,
  | 'daysTillEvent'
  | 'candidates'
  | 'interviewers'
  | 'candidateCount'
  | 'commentCount'
  | 'locked'
  | 'eventReady'
  | 'eventDateTime'
  | 'eventType'
>;

export type PhoneScreenListItem = EventListItem & {
  eventType: EventType.PHONE_SCREEN;
  availabilityId?: OptionalString;
  candidateHireId?: OptionalString;
  candidateHireUrl?: OptionalString;
  candidateName?: OptionalString;
  eventShadowAlias?: OptionalString;
  eventShadowName?: OptionalString;
};

export type EventAvailabilityItem = {
  candidateHireId?: OptionalString;
  candidateHireUrl?: OptionalString;
  daysOfWeek?: OptionalNumber;
  seriesEndDateTime?: Nullable<ElevateDateTime>;
  seriesEndDate?: ElevateDateTime['date'];
  availabilitySeriesID?: OptionalString;
  eventType: EventType;
  expired: boolean;
  id: string;
  filterId: string;
  orgId: string;
  filterOrgId: string;
  orgName?: string;
  preferredTimezoneId: string;
  profileAlias: string;
  profileId: string;
  profileLevel: number;
  profileLevelFilter: string;
  profileName: string;
  recurrence?: Nullable<RecursionInterval>;
  rrule?: OptionalString;
  requester?: OptionalString;
  sendCalendarInvite?: OptionalBool;
  slotOpen: Nullable<ElevateDateTime>;
  slotClose: Nullable<ElevateDateTime>;
  slotDate: ElevateDateTime['date'];
  slotStartTime: ElevateDateTime['displayTime'];
  slotEndTime: ElevateDateTime['displayTime'];
  createdAt: string;
  state: AvailabilityState | null;
  title: OptionalString;
  isPartOfSeries: boolean;
  updatedAt: string;
};

type OpportunityItem = {
  ineligibleReason: OptionalString;
  /** Indicates whether the current user can `release` this claimed opportunity (meaning they own the claim) */
  isEligibleToRelease: boolean;
  /** Indicates if the current user is elegible to claim the event opportunity */
  isEligibleToClaim: boolean;
  isEligibleToParticipate: boolean;
  isRestricted: boolean;
};

export type EventOpportunity<T extends EventListItem> = T & OpportunityItem;

export type CollaboratingParticipant = {
  id: string;
  mode: EventParticipantMode;
  orgId: string;
  calibrationId: string | null;
  myOrgCalibrationId: string | null;
};
