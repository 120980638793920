import type { ResourcesConfig } from 'aws-amplify';
import { parseAmplifyConfig } from 'aws-amplify/utils';

import amplifyConfig from '@/amplifyconfiguration.json';

const PROD_URLS = ['https://elevate.hire.aws.dev/', 'https://elevate.aws.dev/', 'https://prod.elevate.aws.dev/'];
const DEVELOPMENT_URLS = ['https://dev.elevate.hire.aws.dev/', 'https://dev.elevate.aws.dev/'];
const STAGING_URLS = ['https://staging.elevate.aws.dev/', 'https://staging.elevate.hire.aws.dev/'];

export function getResourceConfig(): ResourcesConfig {
  const resourcesConfig = parseAmplifyConfig(amplifyConfig);

  if (!resourcesConfig.Auth?.Cognito) return resourcesConfig;
  // Prevent unauth access and disable IAM credential retrieval
  resourcesConfig.Auth.Cognito.identityPoolId = undefined;
  resourcesConfig.Auth.Cognito.allowGuestAccess = false;

  if (!resourcesConfig.Auth.Cognito.loginWith?.oauth) return resourcesConfig;

  switch (import.meta.env.REACT_APP_BUILD_ENV) {
    case 'prod':
      resourcesConfig.Auth.Cognito.loginWith.oauth.redirectSignIn = PROD_URLS;
      resourcesConfig.Auth.Cognito.loginWith.oauth.redirectSignOut = PROD_URLS;
      resourcesConfig.Auth.Cognito.loginWith.oauth.domain = 'elevate-prod.auth.us-west-2.amazoncognito.com';
      resourcesConfig.Auth.Cognito.userPoolId = 'us-west-2_wRZizMW3B';
      resourcesConfig.Auth.Cognito.userPoolClientId = '1gg7084v9rdvcnheev61k86lfj';
      break;
    case 'staging':
      resourcesConfig.Auth.Cognito.loginWith.oauth.redirectSignIn = STAGING_URLS;
      resourcesConfig.Auth.Cognito.loginWith.oauth.redirectSignOut = STAGING_URLS;
      resourcesConfig.Auth.Cognito.loginWith.oauth.domain = 'elevate-staging.auth.us-west-2.amazoncognito.com';
      resourcesConfig.Auth.Cognito.userPoolId = 'us-west-2_pTsTSQjy7';
      resourcesConfig.Auth.Cognito.userPoolClientId = '4a8phteob5krjvuepmh0ulbr9h';
      break;
    default:
      resourcesConfig.Auth.Cognito.loginWith.oauth.redirectSignIn = DEVELOPMENT_URLS;
      resourcesConfig.Auth.Cognito.loginWith.oauth.redirectSignOut = DEVELOPMENT_URLS;
      resourcesConfig.Auth.Cognito.loginWith.oauth.domain = 'elevate-devnew.auth.us-west-2.amazoncognito.com';
      resourcesConfig.Auth.Cognito.userPoolId = 'us-west-2_SiDx3b1Sa';
      resourcesConfig.Auth.Cognito.userPoolClientId = '2c3s00vvfqjsgs50u1c9bhel8s';
  }

  if (import.meta.env.MODE === 'development') {
    resourcesConfig.Auth.Cognito.loginWith.oauth.redirectSignIn = ['http://localhost:3000/'];
    resourcesConfig.Auth.Cognito.loginWith.oauth.redirectSignOut = ['http://localhost:3000/'];
  }
  return resourcesConfig;
}
