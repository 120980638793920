import { type URLSearchParams } from 'node:url';

import { ElevateApps } from '@/graphql/types';
import { ElevateAppRoutes } from '@/utilities/page-definitions';
import { encodeToPublicID } from '@/utilities/uuid-encoder';

export enum Actions {
  CREATE,
  DETAIL,
  LIST,
  UPDATE,
}

export enum Models {
  INTERVIEWS,
  INTERVIEW_TEMPLATES,
  TEAMS,
}

type IModelKeys = {
  [K in Models]: string;
};

export const Prefixes: IModelKeys = {
  [Models.TEAMS]: 'teams',
  [Models.INTERVIEWS]: 'interviews',
  [Models.INTERVIEW_TEMPLATES]: 'interview-templates',
} as const;

export const DynamicSegmentParams: IModelKeys = {
  [Models.TEAMS]: 'teamID',
  [Models.INTERVIEWS]: 'interviewID',
  [Models.INTERVIEW_TEMPLATES]: 'interviewTemplateID',
} as const;

export const DynamicSegments: IModelKeys = {
  [Models.INTERVIEWS]: `:${DynamicSegmentParams[Models.INTERVIEWS]}`,
  [Models.INTERVIEW_TEMPLATES]: `:${DynamicSegmentParams[Models.INTERVIEW_TEMPLATES]}`,
  [Models.TEAMS]: `:${DynamicSegmentParams[Models.TEAMS]}`,
} as const;

type IStaticSegments = {
  [Actions.CREATE]: string;
  [Actions.UPDATE]: string;
};

export const StaticSegments: IStaticSegments = {
  [Actions.CREATE]: 'create',
  [Actions.UPDATE]: 'update',
};

type IModelPath = {
  [K in Actions]: string | ((id: string | undefined) => string);
};

export const Paths: IModelPath = {
  [Actions.CREATE]: StaticSegments[Actions.CREATE],
  [Actions.DETAIL]: (id: string | undefined) => {
    if (!id) throw new Error('required ID not provided');
    return encodeToPublicID(id) || id;
  },
  [Actions.LIST]: '',
  [Actions.UPDATE]: (id: string | undefined) => {
    if (!id) throw new Error('required ID not provided');
    return `${encodeToPublicID(id) || id}/${StaticSegments[Actions.UPDATE]}`;
  },
} as const;

type Options = {
  hash?: string;
  root?: string;
  searchParams?: URLSearchParams;
};

const URL_PREFIX = 'https://aws.dev';

export function pathTo(action: Actions, model: Models, id?: string, options?: Options): string {
  const actionPath: string = typeof Paths[action] === 'function' ? Paths[action].call(null, id) : Paths[action];
  const path: string = `/${Prefixes[model]}/${actionPath}`;
  const root = options?.root ?? ElevateAppRoutes[ElevateApps.QUESTION_BANK];
  const url = new URL(`${root}${path}`, URL_PREFIX);

  if (options?.hash) {
    url.hash = options.hash;
  }

  if (options?.searchParams) {
    for (const [key, value] of options.searchParams) {
      url.searchParams.set(key, value);
    }
  }

  const final = url.toString();

  /*
   * Slice the absolute part of the URL off to get the relative bits, including
   * the hash and search params.
   */
  return final.slice(URL_PREFIX.length);
}
