import { DateTime, SystemZone } from 'luxon';
import { useEffect, useMemo, useState } from 'react';

import { getFilteredTimezones } from '../timezones';

const SYSTEM_ZONE = new SystemZone().name.toLowerCase();

export function useTimezones(date?: string | null) {
  const [refDate, setRefDate] = useState('');
  useEffect(() => {
    if (date && DateTime.fromISO(date).isValid) {
      setRefDate(date);
    }
  }, [date]);
  const timezones = useMemo(() => getFilteredTimezones(refDate), [refDate]);
  const systemZoneId = useMemo(
    () => timezones.find((tz) => tz.filteringTags?.includes(SYSTEM_ZONE))?.id ?? SYSTEM_ZONE,
    [timezones]
  );

  return { timezones, systemZoneId };
}

export default useTimezones;
