import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ConsoleLogger, Hub } from 'aws-amplify/utils';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';

import useTimezones from '../common/hooks/use-timezones';
import { getCollaborationDefinitions } from '@/backend/api/organization';
import { getUserProfile } from '@/backend/api/profile';
import { useCognitoSession } from '@/backend/auth/cognito-auth';
import { QueryKeys } from '@/backend/queryKeys';
import { amplifyFetch } from '@/common/data-provider';
import { Nullable, OptionalString } from '@/common/models';
import { extractParticipantsToMap } from '@/components/common/collaboration/data-helpers';
import { CollaboratingParticipant } from '@/components/common/event/models';
import { CalibrationState } from '@/components/EventCalibration/models';
import { updateProfile } from '@/graphql/mutations';
import { CalibrationStatus, gql, Roles } from '@/graphql/types';
import { CollaborationItem, UserProfile } from '@/models';
import { AuthGroup } from '@/utilities/permissions';
import { encodeToPublicID } from '@/utilities/uuid-encoder';

const DEPRECATED_RECRUITER_ROLES = new Set([Roles.RECRUITING_COORDINATOR, Roles.RECRUITING_POC, Roles.RECRUITING_SOURCER]);

const logger = new ConsoleLogger('SessionConfig');

type SessionConfig = {
  activeOrgID?: string;
  encodedOrgId?: string;
  activeOrgName?: string;
  calibrationStatuses?: CalibrationState[];
  employeeId?: OptionalString;
  isBarRaiser?: boolean;
  isBRIT?: boolean;
  jobLevel?: number;
  memberOrgs?: { id: string; name: string }[];
  orgPermissions?: OrgGroupPermission;
  preferredTimezoneId?: string;
  profileAlias?: string;
  profileID?: string;
  profileName?: string;
  profileTitle?: string;
};

//                           Org ID | Group Name | Auth Role Name
type OrgGroupPermission = Map<string, Map<string, Set<AuthGroup>>>;

type ParticipantMap = {
  inbound: Map<string, CollaboratingParticipant[]>;
  outbound: Map<string, CollaboratingParticipant[]>;
};

type MultiOrgSessionParams = {
  collaboratingOrgIds: { inbound: Set<string>; outbound: Set<string> };
  barRaiserOrgIds: string[];
  loopOrgIds: string[];
  phoneScreenOrgIds: string[];
  loopParticipantIdMaps: ParticipantMap;
  psParticipantIdMaps: ParticipantMap;
};

type SessionDefinition = {
  changeActiveOrg: ((v: string) => Promise<void>) | null;
  finishedLoading: boolean;
  orgSession: MultiOrgSessionParams | null;
  refreshOrgSession: (() => Promise<void>) | null;
  sessionObject: SessionConfig;
  setupSession: (() => Promise<void>) | null;
};

const emptySession = {
  changeActiveOrg: null,
  finishedLoading: false,
  orgSession: null,
  refreshOrgSession: null,
  sessionObject: {},
  setupSession: null,
} as const satisfies SessionDefinition;

export const SessionConfigContext = createContext<SessionDefinition>(emptySession);

function mapProfileData(data: Nullable<UserProfile>, orgId?: OptionalString): SessionConfig | null {
  if (!data) return null;
  const profile = data;
  const memberOrgs = profile.orgs.map((item) => item);
  // This has to be a Set because a single group might control multiple roles.
  const orgPermissions = new Map<string, Map<string, Set<AuthGroup>>>();
  const activeOrgId = orgId || profile.activeOrg || memberOrgs?.at(0)?.id;
  for (const org of memberOrgs ?? []) {
    const orgGroupMap = new Map<string, Set<AuthGroup>>();
    for (const group of org.roleGroups) {
      let set = orgGroupMap.get(group.group);
      if (set === undefined) {
        const newSet = new Set<AuthGroup>();
        orgGroupMap.set(group.group, newSet);
        set = newSet;
      }
      if (DEPRECATED_RECRUITER_ROLES.has(group.role)) set.add(Roles.RECRUITER);
      set.add(group.role as AuthGroup);
    }
    orgGroupMap.set(Roles.CALIBRATION_SHEPHERD, new Set([Roles.CALIBRATION_SHEPHERD]));
    orgPermissions.set(org.id, orgGroupMap);
  }
  return {
    employeeId: profile.employeeId,
    profileID: profile.id,
    profileAlias: profile.alias,
    profileTitle: profile.title ?? '',
    profileName: profile.name ?? '',
    jobLevel: profile.level ?? undefined,
    isBarRaiser: profile.br ?? false,
    isBRIT: profile.brit ?? false,
    activeOrgID: activeOrgId,
    encodedOrgId: encodeToPublicID(activeOrgId),
    activeOrgName: memberOrgs?.find((org) => org.id === activeOrgId)?.name,
    memberOrgs: memberOrgs ?? [],
    preferredTimezoneId: profile.preferredTimezone ? JSON.parse(profile.preferredTimezone)?.id : undefined,
    orgPermissions,
    calibrationStatuses: (profile.calibrations?.items ?? [])
      .filter((opt) => !!opt.calibrationConfigID)
      .map((opt) => ({
        id: opt.id,
        status: opt.calibrationstatus ?? CalibrationStatus.NOT_CALIBRATED,
        shadowCount: opt.shadowCount ?? 0,
        reverseShadowCount: opt.reverseShadowCount ?? 0,
        independentCount: opt.independentCount ?? 0,
        shepherdReviewCount: opt.reviewCount ?? 0,
        calibrationConfigId: opt.calibrationConfigID!,
        orgId: opt.orgID,
      })),
  };
}

type IUseUpdateTimeZone = {
  profileID: string;
  preferredTimezone: string;
};

function useSessionConfig(orgId?: OptionalString) {
  const cognitoSession = useCognitoSession();
  const queryClient = useQueryClient();
  const { systemZoneId } = useTimezones();

  const queryKey = QueryKeys.profile.alias(cognitoSession.data?.username);

  const updateTimezoneMutation = useMutation({
    mutationFn: ({ profileID, preferredTimezone }: IUseUpdateTimeZone) =>
      amplifyFetch({
        document: gql(updateProfile),
        variables: { input: { id: profileID, preferredTimezone: preferredTimezone } },
      }),
    onSuccess: async () => await queryClient.invalidateQueries({ queryKey }),
  });

  const query = useQuery({
    queryKey,
    queryFn: () => getUserProfile(cognitoSession.data?.username),
    // memoize with stable function pointer
    select: (data) => mapProfileData(data, orgId),
    enabled: !!cognitoSession.data,
  });

  useEffect(() => {
    if (query.status === 'success' && !!query.data?.profileID && !query.data.preferredTimezoneId) {
      const preferredTimezone = JSON.stringify({ id: systemZoneId });
      updateTimezoneMutation.mutate({ profileID: query.data.profileID, preferredTimezone });
    }
  }, [systemZoneId, query.data, updateTimezoneMutation, query.status]);

  return query;
}

type UpdateActiveOrgParams = {
  profileID: string;
  orgID: string;
};

function useUpdateActiveOrg() {
  const sessionConfigQuery = useSessionConfig();
  return useMutation({
    mutationFn: ({ profileID, orgID }: UpdateActiveOrgParams) =>
      amplifyFetch({
        document: gql(updateProfile),
        variables: { input: { id: profileID, activeOrg: orgID } },
      }),
    onSuccess: () => sessionConfigQuery.refetch(),
  });
}

function mapActiveOrgCollaborations(
  data: Nullable<CollaborationItem[]>,
  activeOrgId: OptionalString
): MultiOrgSessionParams | null {
  if (!data || !activeOrgId) return null;

  const barRaiserOrgIds = new Set<string>([activeOrgId]);
  const loopOrgIds = new Set<string>([activeOrgId]);
  const phoneScreenOrgIds = new Set<string>([activeOrgId]);
  const collaboratingOrgIds = {
    inbound: new Set<string>(),
    outbound: new Set<string>(),
  };
  const loopParticipantIdMaps: ParticipantMap = {
    inbound: new Map<string, CollaboratingParticipant[]>(),
    outbound: new Map<string, CollaboratingParticipant[]>(),
  };
  const psParticipantIdMaps = {
    inbound: new Map<string, CollaboratingParticipant[]>(),
    outbound: new Map<string, CollaboratingParticipant[]>(),
  };

  for (const collaboration of data) {
    const collabOrgId = collaboration.originatingOrgID || collaboration.targetOrgID!;
    const collabType = collaboration.originatingOrgID ? 'inbound' : 'outbound';
    const { sharedLoopParticipants, sharedPSParticipants, targetParticipants, originParticipants, barRaisers } =
      collaboration;

    let myOrgPtx = originParticipants;
    let otherOrgPtx = targetParticipants;
    if (collabType === 'inbound') {
      myOrgPtx = targetParticipants;
      otherOrgPtx = originParticipants;
      if (sharedLoopParticipants) loopOrgIds.add(collabOrgId);
      if (sharedPSParticipants) phoneScreenOrgIds.add(collabOrgId);
      if (barRaisers) barRaiserOrgIds.add(collabOrgId);
    }
    collaboratingOrgIds[collabType].add(collabOrgId);
    const loopParticipantIdMap = loopParticipantIdMaps[collabType];
    const psParticipantIdMap = psParticipantIdMaps[collabType];
    extractParticipantsToMap(sharedLoopParticipants, myOrgPtx, otherOrgPtx, collabType, loopParticipantIdMap);
    extractParticipantsToMap(sharedPSParticipants, myOrgPtx, otherOrgPtx, collabType, psParticipantIdMap);
  }

  return {
    collaboratingOrgIds,
    barRaiserOrgIds: [...barRaiserOrgIds],
    loopOrgIds: [...loopOrgIds],
    phoneScreenOrgIds: [...phoneScreenOrgIds],
    loopParticipantIdMaps,
    psParticipantIdMaps,
  };
}

function useActiveOrgCollaborations(orgId: OptionalString) {
  const sessionConfigQuery = useSessionConfig(orgId);

  return useQuery({
    queryKey: QueryKeys.org(sessionConfigQuery.data?.activeOrgID).details.collaborations,
    queryFn: () => getCollaborationDefinitions(sessionConfigQuery.data?.activeOrgID),
    select: (data) => mapActiveOrgCollaborations(data, sessionConfigQuery.data?.activeOrgID),
    enabled: !!sessionConfigQuery.data,
  });
}

export const SessionConfigProvider = ({ children }) => {
  const [activeOrg, setActiveOrg] = useState<OptionalString>(null);
  const sessionConfigQuery = useSessionConfig(activeOrg);
  const orgCollabsQuery = useActiveOrgCollaborations(activeOrg);
  const orgSwitchMutation = useUpdateActiveOrg();
  const cognitoSession = useCognitoSession();
  const sessionObject = sessionConfigQuery.data ?? {};
  const orgSession = orgCollabsQuery.data ?? null;

  useEffect(() => {
    const cancellableListener = Hub.listen('auth', (data) => {
      const { payload } = data;
      // Listen for any 'signIn'/'tokenRefresh' events from the Amplify auth module
      logger.debug('[useEffect][Amplify auth listener] - received event', payload);
      const signInEvents = new Set<typeof payload.event>(['signedIn', 'tokenRefresh']);
      if (signInEvents.has(payload.event) && !cognitoSession.isFetching) void cognitoSession.refetch();
    });
    return () => cancellableListener();
  }, [cognitoSession]);

  /*
    There are two lazy queries we're using to show a loading spinner, so we can't just use isLoading.
    From the docs:
    https://tanstack.com/query/v4/docs/react/guides/disabling-queries#isinitialloading
    Lazy queries will be in status: 'loading' right from the start because loading means that there is no data yet.
    This is technically true, however, since we are not currently fetching any data (as the query is not enabled),
    it also means you likely cannot use this flag to show a loading spinner.
   */
  const queries = [cognitoSession, sessionConfigQuery, orgCollabsQuery];
  const finishedLoading = !(queries.some((q) => q.isLoading || q.data === undefined) || orgSwitchMutation.isPending);

  const changeActiveOrg = useCallback(
    async (orgID: string) => {
      const profileID = sessionConfigQuery.data?.profileID;
      if (!profileID || orgID === sessionConfigQuery.data?.activeOrgID) return;
      setActiveOrg(orgID);

      // Moved here to fix a papercut where the user's default org ID never gets updated, and they constantly need to reset it when returning to the tool.
      // The downside is that it brings up the full page Elevate Spinner...
      await orgSwitchMutation.mutateAsync({ profileID, orgID });
    },
    [orgSwitchMutation, sessionConfigQuery.data?.activeOrgID, sessionConfigQuery.data?.profileID]
  );

  const refreshOrgSession = useCallback(async () => {
    await orgCollabsQuery.refetch();
  }, [orgCollabsQuery]);

  const setupSession = useCallback(async () => {
    await sessionConfigQuery.refetch();
  }, [sessionConfigQuery]);

  return (
    <SessionConfigContext.Provider
      value={{
        sessionObject,
        changeActiveOrg,
        setupSession,
        finishedLoading,
        orgSession,
        refreshOrgSession,
      }}
    >
      {children}
    </SessionConfigContext.Provider>
  );
};

export function useSession() {
  return useContext(SessionConfigContext);
}
